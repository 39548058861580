<template>
  <div class="jumbotron">
    <b-card class="card-container">
      <b-card-header>
        <h1>Create Dashboard</h1>
      </b-card-header>
      <b-card-body>
        <b-form>
          <b-form-group
            id="dashboard-name-id"
            label="Dashboard Name:"
            label-for="dashboard-name"
            description="Give your dashboard a short, readable name. This is what you'll refer to the dashboard as day-to-day. Don't put the name of the boundary in the name, as we'll be displaying that too when we list dashboards."
          >
            <b-form-input
              id="dashboard-name"
              v-model="dashboard.dashboard_name"
              type="text"
              required
              placeholder="e.g. 'Risk in Victoria'"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="dashboard-boundary-id"
            label="Boundary Name:"
            label-for="boundary-name"
            description="Enter the boundary name. This needs to be exact, so check with us first."
          >
            <b-form-input
              id="boundary-name"
              v-model="dashboard.boundary_name"
              type="text"
              required
              placeholder="e.g. 'VICTORIA'"
            ></b-form-input>
          </b-form-group>

          <b-button type="button" @click="createDashboard()" variant="success">Create</b-button>

          <div
            v-if="message"
            class="alert"
            :class="successful ? 'alert-success' : 'alert-danger'"
          >{{message}}</div>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import DashboardService from '../services/dashboard.service';

export default {
  name: 'Dashboards',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FontAwesomeIcon
  },
  data() {
    return {
      dashboard: { dashboard_name: '', boundary_name: '', schema: {} },
      content: '',
      submitted: false,
      successful: false,
      message: ''
    };
  },
  methods: {
    createDashboard() {
      return DashboardService.createDashboard(this.dashboard).then(
        response => {
          console.log("Create response:")
          console.log(response)
          this.$router.push(`/dashboard/edit/${response.data.id}`)
        },
        error => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  mounted() {}
};
</script>

<style scoped>
</style>
